$(document).on('turbolinks:load', function(){

  var birdIcon = L.icon({
      iconUrl: '/assets/bird_b.svg',

      iconSize:     [38, 65], // size of the icon
      iconAnchor:   [22, 58], // point of the icon which will correspond to marker's location
      popupAnchor:  [2, -58] // point from which the popup should open relative to the iconAnchor
  });


  if ($("#locations-map").length){
    var map = L.map('locations-map').setView([47.5596, 7.5886], 13);
  
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);
  
    let get_all = new URLSearchParams(window.location.search).get("all") == "true"

    $.ajax({
      dataType: "json", 
      url: get_all ? "/locations?all=true" : "/locations",
      method: 'get',
      success:function(data){
        $(data).each(function(i, elem){
          L.marker([elem['coordinate_x'], elem['coordinate_y']], {icon: birdIcon}).addTo(map)
              .bindPopup("<b>" + elem['title'] + "</b><br><a target='_blank' href='https://openstreetmap.org/#map=19/" + elem['coordinate_x'] + "/" + elem['coordinate_y'] + "'>" + elem['address'] + ", " + elem['place'] + "</a>")
              //.openPopup();
        })
      }
    });
  }

  if ($("#get-locations-map").length){
    var map = L.map('get-locations-map').setView([47.5596, 7.5886], 13);
    var lat, lng;
  
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);

    var currentLocation = L.marker([$("#location_coordinate_x").val(), $("#location_coordinate_y").val()], {icon: birdIcon}).addTo(map)

    map.addEventListener('mousemove', function(ev) {
       lat = ev.latlng.lat;
       lng = ev.latlng.lng;
    });
  
    document.getElementById("get-locations-map").addEventListener("contextmenu", function (event) {
        // Prevent the browser's context menu from appearing
        event.preventDefault();
        
        $("#location_coordinate_x").val(lat);
        $("#location_coordinate_y").val(lng);

        currentLocation.remove();
        currentLocation = L.marker([lat, lng], {icon: birdIcon}).addTo(map);
  
        return false; // To disable default popup.
    });
  }
});
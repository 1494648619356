// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()
require("trix")
require("@hotwired/turbo-rails")
require("@hotwired/stimulus")
require("leaflet")
require("@rails/actiontext")
require("@rails/actioncable")

require("map.js")
require("custom_trix.js")
require("translateable_input.js")

import "controllers"

$(document).on('turbolinks:load', function () {

  function shrinkLogo(bool) {
    if (bool) {
      $("#header-bar").addClass("scrolled");
      $("#body").addClass("scrolled");
    } else {
      $("#header-bar").removeClass("scrolled");
      $("#body").removeClass("scrolled");
    }
  }

  if ($("#color-scheme").length) {
    $("body").get(0).style.setProperty("--col1", $("#color-scheme").data("col1"));
    $("body").get(0).style.setProperty("--col2", $("#color-scheme").data("col2"));
  }
  $("#menu-open").change(function () {
    if (this.checked) {
      shrinkLogo(true);
      $('header').addClass('orange');
    } else if (!this.checked && $(window).scrollTop() == 0) {
      shrinkLogo(false);
      $('header').removeClass('orange');
    }
  })

  $('#header-content-w').on('click', function (e) {
    if ($(e.target).is($('#header-content-w')))
      $('#menu-open').prop('checked', false);
  });


  let offseasonVideoMobile = document.getElementById('offseason-video-mobile');
  let offseasonVideoDekstop = document.getElementById('offseason-video-desktop');
  setTimeout(function () {
    if (offseasonVideoMobile) { offseasonVideoMobile.play(); }
    if (offseasonVideoDekstop) { offseasonVideoDekstop.play(); }
  }, 500);


  if ($(".transparent-header").length) {
    $('#body').addClass('offseason');
    $("header").addClass("transparent")
    $("header").removeClass("orange")
  }
  $(document).on('scroll', () => {
    if ($(".transparent-header").length && !$("#menu-open").prop("checked")) {
      if ($(document).scrollTop() > $("#offseason-video-w").outerHeight(true) - 50
      ) {
        $("header").removeClass("transparent")
        $("header").addClass("orange")
      } else {
        $("header").addClass("transparent")
        $("header").removeClass("orange")
      }
    }
  })



  $(window).on('scroll', function () {
    var scrollPosition = $(this).scrollTop();
    if (scrollPosition >= 300) {
      shrinkLogo(true);
    } else if (scrollPosition < 10) {
      shrinkLogo(false);
    }
  });
});
function showTranslateableInput(attribute, locale) {
  let fields = $(`.translateable_input-field.translateable_input-${attribute}`);
  let labels = $(`.translateable_input-label.translateable_input-${attribute}`);
  fields.addClass('hidden');
  labels.removeClass('active')
  labels.each(function (index, value) {
    let loc = $(this).data('locale')
    if ($(`#translateable_input-field-${attribute}_${loc}`).children('input').val() == '') {
      $(this).addClass('empty')
    } else {
      $(this).removeClass('empty')
    }
  });
  $(`#translateable_input-label-${attribute}_${locale}`).addClass('active');
  $(`#translateable_input-field-${attribute}_${locale}`).removeClass('hidden')
}
function initializeTranslateableInput() {
  let labels = $(".translateable_input-label.active")
  for (let i = 0; i < labels.length; i++) {
    showTranslateableInput(labels[i].dataset.attribute, labels[i].dataset.locale);
  }
  $(".translateable_input-label").on('click', function () {
    showTranslateableInput($(this).data('attribute'), $(this).data('locale'))
  })
}
$(document).on('turbo:load', () => {
  initializeTranslateableInput();
})
$(document).on('turbolinks:load', () => {
  initializeTranslateableInput();
})
$(document).on('turbo:frame-load', () => {
  initializeTranslateableInput();
})
$(document).on('turbo:frame-load', () => {
  initializeTranslateableInput();
})
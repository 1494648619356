$(document).on('turbolinks:load', function () {
    if (window.Trix.config.textAttributes.small == undefined) {
        /* what the newly created button does */
        window.Trix.config.textAttributes.small = {
            tagName: 'small',
            inheritable: true,
            breakOnReturn: false
        }

        /* insert the button visual in the default toolbar */
        addEventListener("trix-initialize", function (event) {
            var buttonHTML = '<button type="button" class="trix-button" data-trix-attribute="small">&nbsp;&nbsp;S&nbsp;&nbsp;</button>'

            event.target.toolbarElement.
                querySelector(".trix-button-group--text-tools").
                insertAdjacentHTML("beforeend", buttonHTML)
        })
    }
});
///* what the newly created button does */
//Trix.config.blockAttributes.hr = {
//    tagName: 'hr',
//    inheritable: true,
//    breakOnReturn: false
//}
//
///* insert the button visual in the default toolbar */
//addEventListener("trix-initialize", function(event) {
//    var buttonHTML = '<button type="button" class="trix-button" data-trix-attribute="hr">&nbsp;&nbsp;–&nbsp;&nbsp;</button>'
//
//    event.target.toolbarElement.
//    querySelector(".trix-button-group--block-tools").
//    insertAdjacentHTML("beforeend", buttonHTML)
//})
//
///* what the newly created button does */
//Trix.config.blockAttributes.center = {
//    tagName: 'center',
//    inheritable: true,
//    breakOnReturn: false
//}
//
///* insert the button visual in the default toolbar */
//addEventListener("trix-initialize", function(event) {
//    var buttonHTML = '<button type="button" class="trix-button" data-trix-attribute="center">&nbsp;&nbsp;=&nbsp;&nbsp;</button>'
//
//    event.target.toolbarElement.
//    querySelector(".trix-button-group--block-tools").
//    insertAdjacentHTML("beforeend", buttonHTML)
//})